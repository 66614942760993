/* global carDeliveryListComponent,moment, axios */
class OfidirectCarDeliveryListComponent extends carDeliveryListComponent {

    getMethods() {
        const superMethods = super.getMethods();
        superMethods.updateLinkToSelectValues = this.updateLinkToSelectValues;
        superMethods.webUserCanChangeAddress = this.webUserCanChangeAddress;
        return superMethods;
    }

    data() {
        const superdata = super.data();
        superdata.selectedDate = '';
        return superdata;
    }

    getComputed() {
        const superComputed = super.getComputed();
        superComputed.minDate = function () {
            return moment().add(1, 'days').format("YYYY-MM-DD");
        };
        superComputed.maxDate = function () {
            if (this.$store.getters.getCustomer.CustomerMaxDeliveryDays)
                return moment().add(this.$store.getters.getCustomer.CustomerMaxDeliveryDays, 'days').format("YYYY-MM-DD");
            if (this.$store.state.setting.MaxDeliveryDays)
                return moment().add(this.$store.state.setting.MaxDeliveryDays, 'days').format("YYYY-MM-DD");
            return null;
        };
        return superComputed;
    }

    webUserCanChangeAddress() {
        return window.app.webUserCanDo("ModifyDelAddress");
    }

    mounted() {
        let superMounted = super.mounted();
        return async function () {
            superMounted.bind(this)();
            if (this.webUserCanChangeAddress()) {
                this.editMode = true;
            }
        };
    }

    async updateSelectValues(updateValue){
        if(updateValue == 'DelDay') {
            let date = event.target.value;
            event.target.value = '';
            let day = moment(date).days();
            let setting = this.$store.state.setting;
            let blockingDays = {
                Monday: setting.Monday,
                Tuesday: setting.Tuesday,
                Wednesday: setting.Wednesday,
                Thursday: setting.Thursday,
                Friday: setting.Friday,
                Saturday: setting.Saturday,
                Sunday: setting.Sunday,
            };
            let blockPublicHolidays = setting.BlockPublicHolidays;
            if (blockPublicHolidays) {
                let range = [this.minDate, this.maxDate];
                let res = await axios.get('/ecommerce/api/publicHoliday/' + range);
                if (res) { 
                    for(let holliday of res.data) {
                        if (date === holliday[4]) {
                            this.$store.dispatch('showNotificacion', {
                                title: 'Error',
                                message: `Dia no disponible para entregas. Por favor elige otra fecha.`,
                                type: 'error'
                            });
                            return;
                        }
                    }
                }
            }
            for (let [key, value] of Object.entries(blockingDays)) {
                if(moment().day(key).days() === day) {
                    if(!value) {
                        this.$store.dispatch('showNotificacion', {
                            title: 'Error',
                            message: `${this.tr(key)} no disponible para entregas. Por favor elige otra fecha.`,
                            type: 'error'
                        });
                        return;
                    } else { 
                        this.selectedDate = date;
                        let eventObj = {};
                        eventObj[`order_${updateValue}`] = date;
                        this.$store.dispatch('updateOrderDeliveryData',eventObj);
                    }
                }
            }
        } else {
            let eventObj = {};
            eventObj[`order_${updateValue}`] = event.target.value;
            this.$store.dispatch('updateOrderDeliveryData',eventObj);
        }
    }

    updateLinkToSelectValues(updateValue) {
        let eventObj = {};
        let fieldName = updateValue.recordKey;
        if (fieldName && !fieldName.startsWith('order_'))
            fieldName = `order_${fieldName}`;
        eventObj[fieldName] = updateValue.value;
        this.$store.dispatch('updateOrderDeliveryData', eventObj);
    }

    getTemplate() {
        return `<div class="col-12">
                  <template v-if="newDeliveryModal">
                    <addressCreateComponent />
                  </template>
                  <div class="col-12" >
                    <div class="mt-3 bordered reset-point" id="resetPoint">
                        <div class="row m-0 align-items-center">
                        <h6 class="col-12 col-lg-6 align-items-center d-flex">
                            <i class="delivery-icon fas fa-address-card fa-2x mr-3"></i>
                            <template v-if="editMode">
                                {{tr('Select a Delivery Address')}}
                            </template>
                            <template v-else>
                                {{tr('Delivery Address')}}
                            </template>
                        </h6>
                        <div v-if="canAddNew && webUserCanChangeAddress()" class="col-12 col-lg-6 text-left text-lg-right">
                            <div class="my-2" v-on:click="openNewDelivery">
                                <button type="button" class="btn btn-light" @click="openNewDeliveryModal">
                                    <i class="fas fa-plus mr-2"></i>
                                    {{tr('New Delivery Address')}}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div  class="row m-0 align-items-start">
                        <div v-if="editMode" class="input-group col-12 col-xl-6">
                                <input class="form-control" v-model="searchText">
                                <div class="input-group-prepend">
                                    <div class="input-group-text"><i class="fa fa-search" /></div>
                                </div>
                                <!--button class="btn btn-custom ml-sm-2 mt-2 mt-sm-0" @click="activeAddress(selectedCode)">Guardar</button -->
                            </div>
                            <div v-else="editMode" class="input-group col-12 col-xl-6">
                                <button v-if="filterDeliveryList.length > 1 && webUserCanChangeAddress()"  class="btn btn-link text-left p-0" @click="editMode=true">{{tr('Change delivery address')}}4</button>
                            </div>
                        </div>
                        <div v-if="filterDeliveryList.length > 0" class="row m-0 px-2 w-100">
                            <template v-if="!editMode">
                                <template v-for="delObj in deliveryList" >
                                    <template v-if="delObj.Code==selectedCode">
                                        <div :key="'deliver-selected'+delObj.Code" class="col-12  px-2">
                                            <div class="address-card my-2" :class="{selected:activeDeliveryAddress(delObj.Code)}"  >
                                              <strong id="deliveryLabel"><i class="delivery-icon fas fa-map-marker-alt mr-1"></i>{{delObj.Name}}</strong>
                                              <p>{{delObj.Address}} </p>
                                              <p>{{delObj.Province}} </p>
                                              <p>{{delObj.City}} </p>
                                              <p>{{delObj.Locality}} </p>
                                              <p>{{delObj.ZipCode}} </p>
                                            </div>
                                        </div>
                                    </template>
                                </template>
                            </template>
                            <template v-else>
                                <template v-for="delObj in filterDeliveryList" >
                                    <div :key="'deliver-'+delObj.Code" class="col-12 col-lg-6 px-2">
                                        <div class="address-card my-2" :class="{selected:activeDeliveryAddress(delObj.Code)}" @click="selectedCode=delObj.Code, activeAddress(selectedCode)">
                                          <div class="col-12">
                                              <strong id="deliveryLabel"><i class="delivery-icon fas fa-map-marker-alt mr-1"></i>{{delObj.Name}}</strong>
                                              <p>
                                                {{delObj.Street}} {{delObj.StreetNumber}} 
                                                <template v-if="delObj.Floor">{{delObj.Floor}}</template>
                                                <template v-if="delObj.Apartment">{{delObj.Apartment}}</template>
                                              </p>
                                              <p>{{delObj.Locality}}  {{delObj.ZipCode}} </p>
                                              <p>{{delObj.Province}}, {{delObj.City}} </p>
                                          </div>
                                        </div>
                                    </div>
                                </template>
                            </template>
                        </div>
                        <div class="row new-address-message text-left text-md-right m-0" v-if="canAddNew">
                            <p class="col-12 mb-1">Puede administrar las direcciones en <router-link to="/profile/Addresses">Perfil > Direcciones</router-link></p>
                        </div>
                    </div>
                    <div class="bordered form-row m-0 mb-2 px-2"  v-if="shipModes.length>0">
                        <h6 class="col-12 align-items-center d-flex mb-3"><i class="delivery-icon fas fa-truck fa-2x mr-3" style="font-size: 1.6em;"></i> {{tr('Reception Details')}}</h6>
                        <template v-for="shipMode of shipModes" >
                            <div :key="'shipMode-'+shipMode.ShipModeCode" class="col-12 col-lg-6 px-2">
                                <div class="address-card row m-0 my-2" :class="{selected:selectedShipMode==shipMode.ShipModeCode}" @click="selectedShipMode=shipMode.ShipModeCode">
                                  <template class="col-12" >
                                      <strong id="deliveryLabel"><i class="delivery-icon fas fa-truck mr-2"></i>{{shipMode.ShipModeName}}</strong>
                                      <p>
                                        {{tr('Cost')}}: 
                                        <template v-if="shipMode.FreeShipMinAmount">
                                            <template v-if="orderTemplate.fields.Total >= shipMode.FreeShipMinAmount">
                                                <del>$ {{shipMode.Cost}}</del>
                                            </template>
                                            <template v-else>
                                                $ {{shipMode.Cost}}
                                            </template>
                                        </template>
                                        <template v-else>
                                            $ {{shipMode.Cost}}
                                        </template>
                                      </p>
                                      <p v-if="shipMode.DelayMinDays && shipMode.DelayMaxDays">
                                        Llega entre {{calculateDate(shipMode.DelayMinDays)}} y el {{calculateDate(shipMode.DelayMaxDays)}}.
                                      </p>
                                      <p v-if="shipMode.FreeShipMinAmount">{{tr('Free shipping for purchases over')}} $ {{shipMode.FreeShipMinAmount}}.</p>
                                  </template>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="reception-details bordered form-row m-0 mb-2 px-2">
                        <h6 class="col-12 align-items-center d-flex mb-3"><i class="delivery-icon fas fa-dolly mr-3" style="font-size: 1.6em;"></i> {{tr('Reception Details')}}</h6>
                        <div class="col-12 row row-cols-1 row-cols-sm-2 m-0" :class="{'row-cols-xl-4': activeDayByZone, 'row-cols-xl-3': !activeDayByZone}">
                            <div class="form-group col px-1 d-none">
                                <label for="inputEmail4">{{tr('Ship Mode')}}</label>
                                <select name="shipmode" class="custom-select mb-3" v-model="selectedShipMode" v-on:change="updateSelectValues('ShipMode')">
                                    <option value="null" selected>{{tr('Select One')}}</option>
                                    <template v-for="shipMode of shipModes">
                                        <option :value="shipMode.ShipModeCode" >{{shipMode.ShipModeName}}</option>
                                    </template v-for="shipMode of shipModes">
                                </select>
                            </div>
                            <div class="form-group col px-1" v-if="activeDayByZone">
                                <label for="deliveryDay">{{tr('Day')}}</label>
                                <input type="date" name="deliveryDay" :min="minDate" :max="maxDate" id="min" class="form-control" v-on:change="updateSelectValues('DelDay')" :value=selectedDate />
                            </div>
                            <div class="form-group col px-1">
                                <label for="receptionManager">{{tr('Reception Manager')}}</label>
                                <template v-if="selectedCC">
                                    <selectLinkToComponent 
                                        :recordKey="'order_ReceptionManagerCode'"
                                        :selected="$store.state.order_ReceptionManagerCode ? $store.state.order_ReceptionManagerCode : $store.state.session.fields.Code"
                                        class="form-group"
                                        table="CenterCostContactRow"
                                        :paste="'PersonCode'"
                                        showField="PersonName"
                                        :filters="centerCostFilter"
                                        @update-value-full="updateLinkToSelectValues">
                                    </selectLinkToComponent>
                                </template>
                                <template v-else>
                                    <template v-if="emptyCC">
                                        <selectLinkToComponent 
                                            :recordKey="'order_ReceptionManagerCode'"
                                            :selected="$store.state.order_ReceptionManagerCode ? $store.state.order_ReceptionManagerCode : $store.state.session.fields.Code"
                                            class="form-group" 
                                            table="Person" 
                                            :paste="'Code'" 
                                            showField="concat(Name, ' ',LastName)" 
                                            :filters="{CustCode:$store.state.session.fields.CustCode}" 
                                            @update-value-full="updateLinkToSelectValues">
                                        </selectLinkToComponent>
                                    </template>
                                    <template v-else>
                                        <select class="form-control mb-3 disabled" readonly disabled>
                                            <option :value="$store.state.session.fields.Code" selected> {{$store.state.session.fields.Name}} {{$store.state.session.fields.LastName}} </option>
                                        </select>
                                    </template>
                                </template>
                            </div>
                            <div class="form-group col px-1">
                                <label for="delveryHour">{{tr('Hours')}}</label>
                                <select class="custom-select mb-3" v-on:change="updateSelectValues('DelHour')">
                                    <option value="2">9 a 18</option>
                                </select>
                            </div>
                            <div class="form-group col px-1">
                                <label for="PurchaseOrder">{{tr('Purchase Order')}}</label>
                                <input class="form-control mb-3" v-on:change="updateSelectValues('CustPurchaseOrder')">
                            </div>
                        </div>
                    </div>
                    <div class="bordered form-row m-0 mb-2 px-2" v-if="renderCommentSection">
                        <h6 class="col-12 align-items-center d-flex mb-3"><i class="delivery-icon fas fa-comment fa-2x mr-3" style="font-size: 1.6em;"></i> {{tr('Comments')}}</h6>
                        <div class="form-group col-12 col-md-6" :class="{'d-none':!showCommentSt}">
                           <label>{{tr('Additional information about the address')}}</label>
                           <textarea maxlength="195" class="form-control" v-model="commentSt" rows="3" :placeholder="tr('Address observations, between which streets, other references...')" @change="buildComment" ></textarea>
                        </div>
                        <div class="form-group col-12 col-md-6" :class="{'d-none':!showCommentSO}">
                          <label>{{tr('Comments about the order')}}</label>
                          <textarea maxlength="195" class="form-control" v-model="commentSO" rows="3" :placeholder="tr('Comments about the order, reception manager, etc...')" @change="buildComment" ></textarea>
                        </div>
                    </div>
                  </div>
            </div>`;
    }
}

OfidirectCarDeliveryListComponent.registerComponent();

